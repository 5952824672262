@media only screen and (max-width: 1180px) {
    .rl-padding {
    	padding-left: 100px;
    }
    .rr-padding {
    	padding-right: 100px;
    }
    .tp-footernav .tp-footernav-right {
	    float: none; 
	    position: relative;
	    right:0;
	}
}
@media only screen and (max-width: 1080px) {
    .rl-padding {
    	padding-left: 80px;
    }
    .rr-padding {
    	padding-right: 80px;
    }
}

@media only screen and (max-width:991px) {
	.tp-navheader {
		position:relative;
		padding-top:10px;
		top:10px;
	}
	.navbar-toggler-icon {
	    top: 10px;
	    left:4px;
	}
	.tp-article-content img {
		width: 100% !important;
		height:auto !important;
		float: none !important;
		text-align:center;
		left:0 !important;
		right:0 !important;
		margin:0 auto !important;
	}
	.tp-article-cat-hi {
		margin-left:0 !important;
	}
	.tp-footernnc {
		padding: 15px 0 15px 0;
	}
	.tp-featured-img {
		height:425px !important;
	}
	.tp-toggler:focus {
		outline: none !important;
	}
	.tp-featured-sub-container {
		height:450px;
		padding:20px;
	}
	.tp-featured-sub-container-embed {
		height:450px;
		padding:20px;
		width: 100%;
	}
	.tp-featured-subscribe-container .tp-featured-sub-img img {
		display:none;
	}
	.tp-featured-podcast-container .tp-featured-sub-heading {
		margin-top:0px;
		display:none;	
	}
	.tp-featured-sub-container .tp-subscribe {
		padding: 15px;
		margin: 0px 0 15px 0;
	}
	.tp-featured-sub-container-embed .tp-subscribe {
		padding: 15px;
		margin: 0px 0 15px 0;
	}
	.tp-featured-podcast-container {
		padding:15px;
	}
	.tp-featured-podcast-latest {
		display:none;
	}
	.tp-featured-player-container {
		margin-top:40px;
	}
	.tp-subscribethanks {
		height:80px;
	}
	.tp-featured-socan {
		width:75px;
	}
	.tp-featured-podarch {
		float:none;
		right:0;
		left:0;
		margin:0 auto;
		width:75px;
	}
	.tp-city-brampton, .tp-city-mississauga, .tp-city-niagara {
		float:none;
		position:relative;
		left:0;
	}
}
@media only screen and (max-width:980px) {
	.tp-navheader {
		margin-left: 0 !important;
		padding-top:0 !important;
		padding-bottom:10px !important;
	}
	.rl-padding {
    	padding-left: 75px;
    }
    .rr-padding {
    	padding-right: 75px;
    }
    .tp-article-img {
		height: 565px !important;
	}
	.tp-featured-sub-container-embed {
		margin-left:-75px;
		margin-right:-75px;
		width:100%;
		max-width:100%;
	}
}


@media only screen and (max-width:780px) {
	.tp-article-img {
		height: 465px;
	}
	.tp-article-content {
		padding-top:25px;
	}
}

@media only screen and (max-width: 600px) {
	.rl-padding {
		padding-left: 25px;
	}
	.rr-padding {
		padding-right: 25px;
	}
	.mobile-hide {
		display:none;
	}
	.mobile-show {
		display:block;
	}
	.tp-article-img {
		height: 365px;
	}
	.tp-featured-img {
		height:250px !important;
	}
	.tp-footernav li {
	    margin: 0 auto;
	    text-align:center;
	    width:100%;
	}
	.tp-footernav-right a {
		padding-left:25px;
	}
	.tp-article-socialshare .fb-share-button {
		position: absolute;
		display:inline-block;
		top:-8px;
		left: 0;
	}
	.tp-featured-sub-container h2, .tp-featured-sub-container-embed h2 {
		font-size:16px;
	}
	.tp-footernnc, .tp-footerlji {
		padding:10px 0px 10px 0px;
	}
	.tp-featured-sub-container-embed {
		margin-left:-25px;
		margin-right:-25px;
	}
}

@media only screen and (max-width:570px) {
	.tp-navheader {
		font-size: 1.2em !important;
	}
	.tp-home-article-column {
		padding: 0 10px 0 10px;
	}
	.tp-featured-right {
		padding: 25px 10px 25px 10px;
	}
	.tp-article-img {
		height: 365px !important;
	}
}
@media only screen and (max-width: 450px) {
	.rl-padding {
		padding-left: 5px;
	}
	.rr-padding {
		padding-right: 5px;
	}
	.tp-article-img {
		height: 265px !important;
	}
	.tp-featured-sub-container-embed {
		margin-left: -5px;
		margin-right:-5px;
	}
}
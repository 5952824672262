/* Global */
.rl-padding {
	padding-left:175px;
}
.rr-padding {
	padding-right:175px;
}
.mobile-hide {
	display:block;
}
.mobile-show {
	display:none;
}

/* Overrides */
.row {
	margin-left:0 !important;
	margin-right:0 !important;
}
/* Headings */

h1, h3, h5 {
	font-family: "Didot eText W01 Regular";
	color: #000;
}

h2, h4 {
	font-family:"Didot eText W01 Bold";
	color: #000;
}

h1, h2 {
	font-size: 1.5em;
}

h3, h4 {
	font-size: 1.4em;
}

h5 {
	font-size: 1.1em;
}

h6 {
	font-family:"NHaasGroteskTXW01-75Bd";
	font-size: 0.9em;
}

/* Navigation */

/* custom dropdown */
.navbar-collapse ul ul {
	display: none;
	position: absolute; 
	top: 60px; /* the height of the main nav */
}

.navbar-collapse ul li:hover > ul {
	display:inherit;
}
	
/* First Tier Dropdown */
.navbar-collapse ul ul {
	display: none;
	position: absolute; 
	top: 40px; /* the height of the main nav */
	list-style:none;
	background-color:#000;
	padding-left:10px;
}

.navbar-collapse ul li:hover > ul {
	display:inherit;
}

.navbar-collapse ul ul li, .navbar-collapse ul ul li a {
	text-decoration: none;
}
.navbar-collapse ul ul li:hover, .navbar-collapse ul ul li a:hover {
	color: #fff;
}
/* Fisrt Tier Dropdown */
nav ul ul li {
	width:170px;
	float:none;
	display:list-item;
	position: relative;
}


nav ul ul li {
	width:170px;
	float:none;
	display:list-item;
	position: relative;
}

.navbr-collapse {
	color: #fff !important;
}
.tp-navbar {
	background-color:#000;
	padding: 0 0 0 0 !important;
}

.tp-navheader-container {
	width:100%;
	margin:0 auto;
}
.tp-navheader {
	color: #fff;
	text-transform: uppercase;
	text-align:center;
	width:100%;
	position:absolute;
	margin:0 auto;
	top:10px;
}

.navbar-toggler-icon {
  	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  	position: absolute;
  	left: 15px;
  	top: 15px;
}
.navbar-collapse {
	width:auto !important;
}
.nav-link:hover {
	color :#fff !important;
}
.tp-navmenu {
	z-index:10000 !important;
}
.tp-navmenu li {
	padding: 0 0 10px 0 !important;
}
.tp-navmenu li a {
	font-family: "NeueHaasGroteskText W01";
	text-transform:uppercase;
	font-size:14px;
	color: #fff;
}

.tp-subheader-container {
	padding:5px;
	background-color: #2A312E;
	color: #fff;
}

.tp-subheader {
	max-width:70%;
}
.tp-subheader-main {
	max-width:60%;
}

/* Cities */
.tp-city-brampton {
	position:absolute;
	left:5px;
	float:left;
}
.tp-city-mississauga {
	position:absolute;
	left:100px;
	float:left;
}

.tp-city-niagara {
	position:absolute;
	left:210px;
	float:left;
}
/* Body */
body, .tp-article-content p, .tp-article-content span {
    font-family: "NeueHaasGroteskText W01" !important;
    font-size: 18px !important;
    color: #000;
    line-height:1.6 !important;
}
/* Home */
.pagination-container {
	text-align: center;
}
.pagination-container ul {
	display:inline-block;
	margin:0;
	padding:0;
	zoom:1;
	*display: inline;
}
.pagination-container li {
	float:left;
}
.tp-lazyloading {
	display:none;
	margin:0 auto;
	left:0;
	right:0;
	display:block;
}
.tp-home-loadmore {
	text-align:center;
	margin: 0 auto;
	width:150px
}
.tp-home-loadmore button {
	background-color: #000;
    color: #fff;
    border-radius: 4px !important;
    font-size: 13px;
    padding: 10px;
    text-transform: uppercase;
    border: 0px !important;
}
.tp-featured-container {
}


.tp-featured-left {
/*	width:100%; */
/*	height:800px;*/
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.tp-featured-right {
	padding: 25px 50px 25px 50px;
}
.tp-featured-right h1 a {
	text-decoration:none;
}
.tp-featured-right h1 a:hover {
	text-decoration:underline;
}
.tp-featured-sub-container {
	padding:50px 25px 50px 25px;
	background-color: #2A312E;
	color: #fff;
	height:450px;
	width:100%;
}
.tp-featured-sub-container-embed {
	padding:50px 25px 50px 25px;
	background-color: #2A312E;
	color: #fff;
	height:450px;
	width: calc(100% + 350px);
	max-width: calc(100% + 350px);
	margin-left:-175px;
	margin-right:-175px;
	margin-bottom:50px;
}
.tp-featured-sub-heading {
	color: #fff !important;
}
.tp-featured-sub-img img {
	width:200px;
	height:auto;
}
.tp-featured-podcast {
	display:block;
}
.tp-featured-podarch {
	width:100px;
	height:auto;
	float:right;
	text-align:right;
	position:absolute;
	right:16px;
	top:0;
}

.tp-featured-podcast h2, .tp-featured-podcast p {
	text-align:left;
}
.tp-featured-player-container iframe {
        min-width: 100%; 
        width: 100px;
        *width: 100%; 
}
.tp-subscribethanks {
	text-align:center;
	margin:0 auto;
	left:0;
	right:0;
	padding-top:15px;
}

/* Article */
.tp-article-date, .tp-article-cat {
	color: #B71540;
	font-family:"NHaasGroteskTXW01-75Bd";
	font-size: 14px;
	letter-spacing: 3px;
	text-transform:uppercase;
}
.tp-article-date-hi, .tp-article-cat-hi {
	background-color: #B71540;
	color: #fff !important;
	font-family:"NHaasGroteskTXW01-75Bd";
	font-size: 14px;
	letter-spacing: 3px;
	text-transform:uppercase;
}
.tp-article-cat-city {
	font-family:"NHaasGroteskTXW01-75Bd";
	font-size:14px;
	color: #000;
	display:inline-block;
}
.tp-article-date-hi {
	padding: 5px 5px 5px 15px;
	margin-left:-15px;
}
.tp-article-cat-hi {
	padding: 5px 5px 5px 5px;
	margin-left:-15px;
}
.tp-article-date {
	padding-right: 15px;
}
.tp-article-cat {
	padding-left: 0px;
}
.tp-article-container {
	width:100%;
}
.tp-home-article-container {
	padding-top: 25px;
	padding-bottom: 25px;
}
.tp-home-article-column {
	padding: 0 50px 0 50px;
}
.tp-home-article-column h1 a {
	text-decoration:none;
}
.tp-home-article-column h1 a:hover {
	text-decoration:underline;
}
.tp-home-article-row {
	padding: 0 0 100px 0;
}
.tp-article-author {
	font-family:"NHaasGroteskTXW01-75Bd";
	text-transform:uppercase;
	font-size:16px;
	color: #000;
}

/* Archives */
.tp-archive-link {
	text-align:center;
    font-family: "NeueHaasGroteskText W01";
    font-size: 18px;
    text-transform: uppercase;
}

/* Single Article */
.tp-article-main-container {
	width:100%;
	height: 100%;
}
.tp-article-content-container {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.tp-article-img {
	width:100%;
	height:565px;
}
.tp-article-img-container {
	width:100%;
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.tp-article-img-textonly {
	width:100% !important;
	height:auto !important;
	text-align:center !important;
	margin:0 auto !important;
	left:0 !important;
	right:0 !important;
}
.tp-article-img-textonly-container {
	width:100%;
	height:100%;
}
.tp-article-content {
	padding-top:50px;
}
.tp-article-byline {
	font-family:"NHaasGroteskTXW01-56It";
	font-size: 14px;
	text-align:left;
}
.tp-article-cutline {
	font-family:"NHaasGroteskTXW01-56It";
	font-size: 14px;
	text-align:center;
}
.tp-article-title {
	font-size: 2.5em !important;
}
.tp-article-socialshare {
	display:inline-block;
	position: relative;
	min-height: 28px;
	min-width: 225px;
	margin: 5px 0 0 0;
}
.tp-article-socialshare .fb-share-button {
	position: absolute;
	display:inline-block;
	top:-7px;
	left: 0;
}
.tp-article-socialshare .tweet-share-button {
	position: absolute;
	display:inline-block;
	top: 0;
	left: 70px;
}
.tp-article-socialshare .email-share-button {
	position:absolute;
	display:inline-block;
	top:-4px;
	left:140px;
	margin-left:5px;
}

/* Buttons */
.tp-subscribe {
	font-family:"NHaasGroteskTXW01-75Bd";
	font-size: 14px;
	letter-spacing: 3px;
	color: #fff !important;
	background-color: #B71540 !important;
	text-transform:uppercase;
	padding: 15px 60px 15px 60px;
	margin: 50px auto;
	border-radius: 8px !important;
	z-index:1000 !important;
}

/* Login / Register */
.tp-login-container {
	margin-bottom: 100px;
}

/* Contact form */
.tp-contact-container {
	width:50%;
	margin-bottom:100px;
}

/* Account page */
.tp-account-dropdown {
	left:-40px !important;
	clear:both;
}
.tp-navmenu .dropdown-item.active, .tp-navmenu .dropdown-item:active {
	background-color: #D3D3D3 !important;
}
.tp-account-dropdown .dropdown-item {
	color: #000 !important;
}
.tp-account-subscribe {
	padding-bottom:200px;
}

/* Footer */
.tp-footer-container {
	background-color: #b71540;
	color: #fff;
	padding: 25px;
}
.tp-footernav {
	display:inline-block;
	padding:0 !important;
	margin-bottom: 0 !important;
}
.tp-footernav-niagara {
	display:inline-block;
	padding: 0 !important;
	text-align:center;
	margin:0 auto;
}
.tp-footernav li, .tp-footernav-niagara li {
	list-style:none;
	display:inline;
	float:left;
	padding-right:10px;
}
.tp-footernav li a, .tp-footernav-niagara li a {
	font-family:"NeueHaasGroteskText W01";
	font-size: 14px;
	text-transform:uppercase;
	color: #fff !important;
	letter-spacing: 1px;
}
.tp-footercopy {
	display:block;
	text-align:center;
    font-family:"NHaasGroteskTXW01-65Md";
    font-size:12px;
    color: #fff;
    letter-spacing: 1px;
}
.tp-footernav .tp-footernav-right {
	float:right;
	position:absolute;
	right:25px;
}
.tp-footericon-right a {
	color: #fff;
}
.tp-footericon {
	padding-right: 25px;
	font-size:28px !important;
}
.tp-footernnc, .tp-footerlji {
	display:inline-block;
	margin-left:auto;
	margin-right:auto;
	width:150px;
	height:auto;
	padding-bottom:10px;
}
.tp-footerlji {
	padding-left:10px;
}


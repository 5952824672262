
// Body
$body-bg: #fff;

// Colors
$headings-color: #000 !default;
$primary: #000 !default;
$link-color: #000 !default;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

@font-face{
    font-family:"Didot eText W01 Regular";
    src:url("../fonts/dbcd27d7-e1e4-4757-b144-32def75c2eaa.eot?#iefix");
    src:url("../fonts/dbcd27d7-e1e4-4757-b144-32def75c2eaa.eot?#iefix") format("eot"),url("../fonts/47c21a47-e2de-449d-a6fc-1a4951ee5e46.woff2") format("woff2"),url("../fonts/6bfc2eb5-d4a7-42d3-a372-305f28511a22.woff") format("woff"),url("../fonts/b66a964d-58b6-42f1-a3f7-fecb060b2ec3.ttf") format("truetype");
}
@font-face{
    font-family:"Didot eText W01 Italic";
    src:url("../fonts/1edaf9be-e132-492c-9591-6d4b8664fbf2.eot?#iefix");
    src:url("../fonts/1edaf9be-e132-492c-9591-6d4b8664fbf2.eot?#iefix") format("eot"),url("../fonts/fae76152-6f1e-46d4-9fb2-19344ac9cb0f.woff2") format("woff2"),url("../fonts/49373f6d-8b3c-4950-ad68-78dd2ed5e1c2.woff") format("woff"),url("../fonts/10a533cb-9d30-4e26-860e-5675fe3b96d4.ttf") format("truetype");
}
@font-face{
    font-family:"Didot eText W01 Bold";
    src:url("../fonts/9734b722-6799-4c89-89ea-eb8dc6675e7b.eot?#iefix");
    src:url("../fonts/9734b722-6799-4c89-89ea-eb8dc6675e7b.eot?#iefix") format("eot"),url("../fonts/085fc2af-7043-4a28-93a8-6d2d96526271.woff2") format("woff2"),url("../fonts/502d881a-6b8a-4146-8431-c02c9d39953b.woff") format("woff"),url("../fonts/43f777fa-2580-4710-b893-05b27cab7c0e.ttf") format("truetype");
}
@font-face{
    font-family:"DidotETW01-BoldItalic";
    src:url("../fonts/3cf96a55-30b1-41eb-b7aa-6f32aa5481ed.eot?#iefix");
    src:url("../fonts/3cf96a55-30b1-41eb-b7aa-6f32aa5481ed.eot?#iefix") format("eot"),url("../fonts/ff3eabee-9b2a-4e52-aed0-faff556f660e.woff2") format("woff2"),url("../fonts/013cef41-b3c8-4a56-a166-8ae9e31604c4.woff") format("woff"),url("../fonts/79a0e1ca-0030-4d42-b861-6dd25f064b3e.ttf") format("truetype");
}
@font-face{
    font-family:"NeueHaasGroteskText W01";
    src:url("../fonts/d24ae558-ac0f-4a43-96da-dd49b68947f5.eot?#iefix");
    src:url("../fonts/d24ae558-ac0f-4a43-96da-dd49b68947f5.eot?#iefix") format("eot"),url("../fonts/a14594bf-73de-4b5f-9792-9566994a021d.woff2") format("woff2"),url("../fonts/bb4a10bb-155d-4c1a-a813-c65e10fac36c.woff") format("woff"),url("../fonts/53812a68-b352-4951-b19c-fe964db7ffe2.ttf") format("truetype");
}
@font-face{
    font-family:"NHaasGroteskTXW01-56It";
    src:url("../fonts/baa1ea73-44ac-4bb5-a6af-b7fc486d335f.eot?#iefix");
    src:url("../fonts/baa1ea73-44ac-4bb5-a6af-b7fc486d335f.eot?#iefix") format("eot"),url("../fonts/dc9df9ed-36b9-4522-8e57-1a899ed2c224.woff2") format("woff2"),url("../fonts/ff571a3a-fb16-42b1-a691-23d8955aa35e.woff") format("woff"),url("../fonts/4e756bdf-4269-4158-aad4-70a09c5eed5c.ttf") format("truetype");
}
@font-face{
    font-family:"NHaasGroteskTXW01-65Md";
    src:url("../fonts/fca16206-1413-42b5-b3dd-ce6499d2bd3f.eot?#iefix");
    src:url("../fonts/fca16206-1413-42b5-b3dd-ce6499d2bd3f.eot?#iefix") format("eot"),url("../fonts/34ae0cd2-c49c-4df4-8270-fcda21c1b715.woff2") format("woff2"),url("../fonts/9e666926-4bc9-4013-849e-dffa25a41dbd.woff") format("woff"),url("../fonts/37e13425-7daf-407c-ba41-43ebd7d30855.ttf") format("truetype");
}
@font-face{
    font-family:"NHaasGroteskTXW01-66MdI";
    src:url("../fonts/a0bf86e3-a9f2-4579-8187-62d3f2386821.eot?#iefix");
    src:url("../fonts/a0bf86e3-a9f2-4579-8187-62d3f2386821.eot?#iefix") format("eot"),url("../fonts/c951fbb4-1116-47e5-b057-5691a20747eb.woff2") format("woff2"),url("../fonts/cfaf1c42-858f-4acc-88d8-f0fd7d3e6295.woff") format("woff"),url("../fonts/602dde2d-6c6d-491a-b06e-6b1d3e3d6939.ttf") format("truetype");
}
@font-face{
    font-family:"NHaasGroteskTXW01-75Bd";
    src:url("../fonts/8d290bc2-1f22-40ea-be12-7000a5406aff.eot?#iefix");
    src:url("../fonts/8d290bc2-1f22-40ea-be12-7000a5406aff.eot?#iefix") format("eot"),url("../fonts/d13fb250-6b64-4d97-85df-51fc6625a891.woff2") format("woff2"),url("../fonts/60fa2ce6-c35e-4203-9bbf-25dd128daec5.woff") format("woff"),url("../fonts/dda121ff-e230-440f-83fb-40aefbd6e09a.ttf") format("truetype");
}

// Variables
@import "variables";

// Bootstrap
@import '../../../node_modules/bootstrap/scss/bootstrap';

// Custom style
@import "custom";

// Responsive style
@import "responsive";

// Font Awesome
@import "../../../node_modules/font-awesome/scss/font-awesome.scss";

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}
